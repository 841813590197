/* StreamlineSection.module.css */

.container {
  padding: 40px 20px; /* Reduced padding for smaller screens */
  background-color: #f9f9fb;
  text-align: center; /* Center text on smaller screens */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  text-align: left;
  color: #1e2a38;
  font-size: 36px; /* Default font size for smaller screens */
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.5;
}

.description {
  text-align: left;
  color: #4a4a4a;
  font-size: 18px; /* Default font size for smaller screens */
  line-height: 1.7;
  max-width: 100%;
  /* margin: 0 auto; */
}

@media (min-width: 768px) {
  /* Styles for tablets and above */
  .container {
    padding: 60px 40px; /* Increased padding for larger screens */
    text-align: left;
  }

  .title {
    font-size: 48px; /* Larger font size for tablets */
  }

  .description {
    font-size: 20px; /* Larger font size for tablets */
    max-width: 600px; /* Constrain max width on tablets */
  }
}

@media (min-width: 1024px) {
  /* Styles for desktops and above */
  .container {
    padding: 60px 80px; /* Further increased padding for desktops */
  }

  .title {
    font-size: 62px; /* Even larger font size for desktops */
  }

  .description {
    max-width: 700px; /* Constrain max width on desktops */
  }
}
