/* PlacementSection.module.css */

.placementSection {
  background-image: url('../images/bg1.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 70vh; /* Default height */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-in-out; /* Fade-in animation */
  overflow: hidden;
  padding: 0 20px; /* Padding to prevent content from touching screen edges */
}

.overlay {
  /* background: linear-gradient(135deg, rgba(103, 108, 148, 0.9), rgba(0, 0, 0, 0.3)); */
  text-align: center;
  padding: 20px 40px; /* Reduced padding for smaller screens */
  border-radius: 12px;
  animation: slideUp 1s ease-out; /* Slide up animation */
  max-width: 90%; /* Limit width for smaller screens */
  margin: 0 auto; /* Center the overlay */
}

.title {
  font-size: 32px; /* Smaller default font size for better responsiveness */
  color: #ffffff; /* White color for contrast */
  margin-bottom: 15px;
  font-family: 'Merriweather', serif; /* Modern serif font */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle text shadow */
}

.subtitle {
  font-size: 18px; /* Smaller default font size */
  font-weight: 300;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); /* Subtle text shadow */
  opacity: 0;
  animation: fadeInText 2s 0.5s forwards; /* Delayed text fade-in animation */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (min-width: 480px) and (max-width: 767px) {
  /* Small devices */
  .placementSection {
    height: 60vh; /* Slightly smaller height for small devices */
  }
  .title {
    font-size: 28px; /* Adjust font size for small devices */
  }
  .subtitle {
    font-size: 16px; /* Smaller font size for small devices */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* Tablets */
  .placementSection {
    height: 60vh; /* Medium height for tablets */
    padding: 0 40px; /* Extra padding for tablets */
  }
  .overlay {
    padding: 40px 60px; /* More padding for larger screens */
    max-width: 70%; /* Wider overlay for tablets */
  }
  .title {
    font-size: 40px; /* Increased font size for tablets */
  }
  .subtitle {
    font-size: 20px; /* Larger font size for tablets */
  }
}

@media (min-width: 1024px) {
  /* Desktops and larger screens */
  .placementSection {
    height: 70vh; /* Larger height for desktops */
    padding: 0 80px; /* Even more padding for desktops */
  }
  .overlay {
    padding: 60px 80px; /* Even more padding for larger screens */
    max-width: 60%; /* Wider overlay for desktops */
  }
  .title {
    font-size: 56px; /* Original large font size */
  }
  .subtitle {
    font-size: 24px; /* Original subtitle size */
  }
}
