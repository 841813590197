/* BackedBy.module.css */

/* Container styles */
.container {
    text-align: center;
    padding: 40px 0;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Image section styles */
  .imageSection {
    margin-bottom: 40px;
  }
  
  /* Image styles */
  .image {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Backed by section styles */
  .backedBySection {
    margin-top: 20px;
  }
  
  /* Heading styles */
  .heading {
    font-size: 44px;
    color: #333;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  /* Logo styles */
  .logo {
    width: 200px;
    height: auto;
    display: inline-block;
    margin-top: 10px;
  }
  