.footer {
    background-color: #f1d067;
    padding: 40px 0;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
  }
  
 
  
  .formGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .inputField,
  .textarea {
    width: 100%;
    padding: 10px;
    background-color: #f7e18e; /* Yellow background for input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000; /* Text color */
    margin-bottom: 15px;
  }
  
  .textarea {
    height: 100px; /* Set default height to 100px */
  }
  
  .inputField::placeholder,
  .textarea::placeholder {
    color: #f1d067; /* Yellow color for placeholder text */
    opacity: 1; /* Ensure the placeholder is fully opaque */
  }
  

  
  .contactForm form {
    text-align: left; /* Align all form elements, including the submit button, to the left */
  }
  
  .submitButton {
    background-color: #4c2a26;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block; /* Make the button behave like a block element */
    margin: 0; /* Remove any default margins */
  }
  
  
  .submitButton:hover {
    background-color: #3a1f1c;
  }
  
  .textSection {
    margin-top: -20px;
    flex: 1;
    margin-left: 20px;
    text-align: left;
  }
  
  .textSection h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .textSection p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .footerBottom {
    margin-top: 30px;
    background-color: #f1d067;
    padding: 10px 0;
    text-align: center;
  }
  
  .footerBottom span {
    font-weight: bold;
  }
  
  .durableLink {
    text-decoration: none;
    color: #4c2a26;
  }
  
  .durableLink:hover {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .textSection {
      text-align: center;
      margin-top: 20px;
    }
  }
    