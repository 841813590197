
.logoSection {
  flex: 0 0 auto; /* Prevents the logo section from growing */
}

.logo {
  font-size: 24px; /* Adjust font size as needed */
  font-weight: bold;
}

.wise {
  color: #000000; /* Black color for "Wise" */
}

.logoSection .logo {
  color: #007bff; /* Blue color for "Campus" */
}

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background: #f5ece1;
    animation: fadeIn 1.5s ease-in-out;
    gap: 20px; 
  }
  
  .textSection {
    flex: 1;
    text-align: left;
    max-width: 600px;
    padding: 20px;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .tagline {
    font-size: 18px;
    font-weight: 500;
    color: rgb(255, 64, 0);
    margin-bottom: 10px;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .title {
    font-size: 48px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.2;
    animation: fadeIn 1.8s ease-in-out;
  }
  
  .description {
    font-size: 20px;
    line-height: 1.9;
    margin-bottom: 35px;
    color: black;
  }
  
  .button {
    background-color: #ff6f61;
    color: #fff;
    padding: 14px 36px;
    border: none;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .button:hover {
    background-color: #e85a50;
  }
  
  .imageSection {
    flex: 1;
    max-width: 500px;
    padding: 20px;
    animation: zoomIn 1.5s ease-in-out;
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    animation: zoomIn 1.5s ease-in-out;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 20px;
      gap: 15px;
      text-align: center;
    }
  
    .textSection {
      max-width: 100%;
      padding: 10px;
    }
  
    .title {
      font-size: 36px;
    }
  
    .description {
      font-size: 16px;
    }
  
    .button {
      font-size: 14px;
      padding: 10px 20px;
    }
  
    .imageSection {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 28px;
    }
  
    .description {
      font-size: 14px;
    }
  
    .button {
      font-size: 12px;
      padding: 8px 16px;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }


  