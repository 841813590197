/* Navbar.module.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 100;
    transition: padding 0.3s;
    position: relative; /* Ensure dropdown is positioned relative to navbar */
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
  }
  
  .wise {
    color: #000000;
  }
  
  .contactButton {
    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-weight: 500px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .contactButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Dropdown menu styling */
  .dropdown {
    display: none; /* Hidden by default */
    position: absolute;
    top: 100%; /* Position just below the navbar */
    left: 0;
    font-weight: 500px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 4px;
    z-index: 200; /* Ensure it is on top of other elements */
  }
  
  /* Show the dropdown menu when isDropdownOpen is true */
  .navbar .dropdown {
    display: block;
  }
  .hamburger{
    display: none;
  }
  
  
  
  .line {
    width: 100%;
    height: 4px;
    background-color: #333;
    border-radius: 2px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .navbar {
      padding: 10px;
    }
  
    .logo {
      text-align: left;
      font-size: 20px;
    }
  
    .hamburger {
      display: flex; /* Show hamburger menu on smaller screens */
      margin-left: auto;
    }
    /* Hamburger menu styling */
  .hamburger {
    display: none;
    cursor: pointer;
    width: 30px; /* Width of the hamburger menu */
    height: 20px; /* Height of the hamburger menu */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
    .dropdown {
      display: flex; /* Display the dropdown when active */
    }
    .desktopButton{
        display: none;
    }
  }
  
  @media (max-width: 480px) {
    .logo {
      font-size: 18px; /* Smaller font size for extra small screens */
    }
  
    .contactButton {
      padding: 6px 12px; /* Smaller padding for button on extra small screens */
      font-size: 12px; /* Smaller font size for button text */
    }
    .desktopButton{
        display: none;
    }
    /* Hamburger menu styling */
  .hamburger {
    display: none;
    cursor: pointer;
    width: 30px; /* Width of the hamburger menu */
    height: 20px; /* Height of the hamburger menu */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  }
  