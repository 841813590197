.servicesSection {
  background-color: #f9f5f0;
  padding: 60px 20px; 
  text-align: center;
}

.heading {
  text-align: center;
  font-size: 32px;
  color: #2e2e2e;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
}

.servicesGrid {
  display: flex;
  justify-content: center; /* Center the grid */
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.serviceCard {
  border-radius: 15px;
  padding: 20px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
  flex: 1 1 300px; /* Flex-grow and flex-shrink with a base width of 300px */
  max-width: 450px; /* Max width to control size */
  max-height: 650px; /* Max height to control size */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center content */
  justify-content: space-between; /* Distribute space between items */
  margin: 10px; /* Adds spacing between cards */
  transition: transform 0.2s ease; /* Smooth transition for hover effect */
}

.serviceImage {
  width: 100%;
  /* max-width: 300px;  */
  /* border-radius: 10px; */
  margin-bottom: 15px;
}

h3 {
  font-size: 16px;
  text-align: left;
  color: #2e2e2e;
  margin-bottom: 10px;
  justify-content: left baseline;
  font-family: 'Poppins', sans-serif;
}

.para {
  font-size: 14px;
  color: #4a4a4a;
  text-align: left;
  text-align: left;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}

.serviceCard p:last-of-type {
  margin-bottom: auto; /* Ensures space is distributed evenly */
}

/* Add hover effect to cards */
.serviceCard:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
}
