/* General Styling */
.aboutUsSection {
  background-color: #f8f0e8; /* Beige background color */
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 40px; /* Adds space between the image and text */
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adds spacing between elements */
}

.aboutUsImage {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
}

.textContainer {
  flex: 2;
  color: #2e2e2e;
  font-family: 'Poppins', sans-serif;
  text-align: center; /* Center text alignment on smaller screens */
  padding: 0 10px; /* Adds padding for smaller screens */
}

.heading {
  font-size: 32px;
  color: #2e2e2e;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: 18px;
  color: #4a4a4a;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Responsive Design */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column; /* Stack the image and text vertically */
    text-align: center; /* Center align text */
    gap: 20px; /* Reduce gap between image and text */
  }

  .heading {
    font-size: 28px; /* Slightly smaller heading font size */
  }

  p {
    font-size: 16px; /* Slightly smaller paragraph font size */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .aboutUsSection {
    padding: 40px 10px; /* Reduced padding for smaller screens */
  }

  .aboutUsImage {
    max-width: 200px; /* Smaller image size for mobile */
  }

  .heading {
    font-size: 24px; /* Smaller heading font size */
  }

  p {
    font-size: 14px; /* Smaller paragraph font size */
  }
}
